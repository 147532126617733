import React from 'react';
import './Content.sass'

function Content(props) {
  return (
    <div className="content">
      { props.children }
    </div>
  );
}

export default Content;